import React from "react";

import ArticleList from "../ui/articleList/ArticleList";
import Layout from "../components/Layout";

const ArticleIndexTemplate = (props) => {
  const { pageContext } = props;
  const {
    articles,
    breadcrumb,
    label,
    image,
    alt,
    filters,
    type,
    description,
  } = pageContext;

  const articleList = (
    <ArticleList
      articles={articles}
      breadcrumb={breadcrumb}
      label={label}
      image={image}
      alt={alt}
      filters={filters}
      type={type}
      loadMoreText="Load more microPublications..."
      description={description}
    />
  );

  return <Layout children={articleList} />;
};

export default ArticleIndexTemplate;
